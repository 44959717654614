import { css } from 'styled-components';
import { styles } from './globals';

export const cleanInputStyles = css`
    border-width: 0;
    box-shadow: none;
    outline: none;
    background: ${(props) => props.error ? styles.colors.lightRed : styles.colors.white};
    display: block;
    margin: 0;
    padding: 0.8rem 1.25rem;
    width: 100%;
    max-width: 100%;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    appearance: none; // not a standard property, but Normalize will prefix it
`;

export default cleanInputStyles;
