/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { colors } from '../styles/globals';
import DefaultLayout from '../containers/default';
import '../styles/index.css';
import AllSymbolIcon from '../svgs/all-symbol.svg';
import {
  HandHeartIcon,
  AccessIcon,
  DuoIcon,
  HeartIcon,
  MindIcon,
} from './icons';
import {
  Section,
  SectionDescription,
  SectionItem,
  SectionTitle,
} from './section';
import {
  MainBanner,
  MainBannerItem,
  MainBannerTitle,
  Banner,
  BannerItem,
  BannerTitle,
  BannerDescription,
} from './banner';

const AllSymbol = styled(AllSymbolIcon)`
  width: 100%;
  height: 99%;
`;

export default () => (
  <DefaultLayout>
    <Helmet>
      <title>all.health</title>
      <meta
        name="description"
        content="We detect lifestyle diseases early and help members manage existing conditons to influence health outcomes. Sign up to learn more."
      />
    </Helmet>
    <MainBanner>
      <MainBannerItem left isIcon>
        <AllSymbol />
      </MainBannerItem>
      <MainBannerItem right wide>
        <MainBannerTitle>
          we enable the most advanced, continuous healthcare – whenever and wherever you need&nbsp;it
        </MainBannerTitle>
      </MainBannerItem>
    </MainBanner>
    <Banner reverse backgroundColor={colors.grey1}>
      <BannerItem left>
        <BannerTitle>
          we make healthcare personal
        </BannerTitle>
        <BannerDescription>
          Healthcare needs to change. It's expensive, slow, and inaccessible.
        </BannerDescription>
        <BannerDescription>
          all.health™ wants to bring the
          {' '}
          <b>care</b>
          {' '}
          back into healthcare. That starts by bringing the relationship between
          doctor and patient closer: how it was meant to be. We've designed the care experience so that the patient
          journey is unbroken, seamless and individualized.
        </BannerDescription>
      </BannerItem>
      <BannerItem right isIcon>
        <HandHeartIcon />
      </BannerItem>
    </Banner>
    <Section>
      <SectionItem left isIcon backgroundColor={colors.cream}>
        <MindIcon />
      </SectionItem>
      <SectionItem right>
        <SectionTitle>
          we give you peace of mind
        </SectionTitle>
        <SectionDescription>
          The all.health system is an informed link to your care team. It makes continuous care possible – whenever and wherever you need it.
          Imagine a doctor who knows what is going on with your health before you even ask. The all.health platform connects
          you to your care team, and walks with you every step of the way to help explore the impact of your everyday
          lifestyle choices and live better.
        </SectionDescription>
      </SectionItem>
    </Section>
    <Section reverse>
      <SectionItem left>
        <SectionTitle>
          designed to bring patients and care teams closer
        </SectionTitle>
        <SectionDescription>
          all.health, for the first time, brings together clinical grade medical technology, the expertise of your local care team, and
          the best consumer wearable experience. all.health's Integrated Continuous Management (ICM™) system collects 24/7 data on vital signs
          while screening for risks of over 50 health conditions. The system forms a continuous link between you and your care team.
          Your care team can now see a longitudinal data view across your entire healthspan and take action based on a medical continuum
          rather than a single snapshot. And our AI models provide actionable insights at the right moment to deliver personalized, proactive,
          and predictive healthcare.
        </SectionDescription>
      </SectionItem>
      <SectionItem right isIcon backgroundColor={colors.pink}>
        <HeartIcon />
      </SectionItem>
    </Section>
    <Section>
      <SectionItem left isIcon backgroundColor={colors.blue}>
        <DuoIcon />
      </SectionItem>
      <SectionItem right>
        <SectionTitle>
          created with providers for providers and their patients
        </SectionTitle>
        <SectionDescription>
          Providers are crucial to developing technology that transforms healthcare and
          population management. all.health partners with providers to integrate continuous,
          remote care into their clinical workflows by strengthening them with AI. Working together,
          we build the all.health system that enables care teams to improve patient care and
          reduce unnecessary office visits, helping clinicians work at the top of their license.
        </SectionDescription>
      </SectionItem>
    </Section>
    <Section reverse>
      <SectionItem left>
        <SectionTitle>
          access to care is care
        </SectionTitle>
        <SectionDescription>
          Healthcare innovation only works if it works for all people. all.health's system removes the common
          barriers to healthcare access – distance, cost, and physical limitations, so we work with providers
          to ensure all people have access to the highest quality care wherever they want.
        </SectionDescription>
      </SectionItem>
      <SectionItem right isIcon backgroundColor={colors.sage}>
        <AccessIcon />
      </SectionItem>
    </Section>
  </DefaultLayout>
);
