import styled from 'styled-components';
import { styles, fonts } from '../styles/globals';
import { cleanInputStyles } from '../styles/utils';

export const StyledInput = styled.input`
    ${cleanInputStyles};
    border: 1px solid
        ${(props) => (props.error ? styles.colors.red : styles.borderColor)};
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
    opacity: 0;
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
`;

export const CheckBoxLabel = styled.label`
    display: inline-block;
    padding: 0 10% 0 2rem;

    &:before {
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        left: 0;
        border: 1px solid ${styles.borderColor};
        background: transparent center bottom no-repeat;
        background-size: 70%;
    }

    ${Checkbox}:checked + & {
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 259 283.75' fillRule='evenodd' clipRule='evenodd' shapeRendering='geometricPrecision' textRendering='geometricPrecision' imageRendering='optimizeQuality'%3E%3Cpath d='M0 0l104 104c5 5 5 14 0 19L0 227h31l89-88c5-5 14-5 19 0l89 88h31L155 123c-5-5-5-14 0-19L259 0h-30l-90 88c-5 5-14 5-19 0L32 0H0z' /%3E%3C/svg%3E");
        }
    }

    a {
        color: #000;
        text-decoration: underline;
    }
`;

export const CheckboxSignup = styled.input.attrs({ type: 'checkbox' })`
    opacity: 0;
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
`;

export const CheckBoxLabelSignup = styled.label`
    font-family: ${fonts.gotham};
    font-size: 14px;
    display: inline-block;
    padding: 0 10% 0 1.5rem;

    &:before {
        content: '';
        width: 1.2rem;
        height: 1.2rem;
        position: absolute;
        left: 0;
        border: 1px solid ${styles.borderColor};
        background: transparent center bottom no-repeat;
        background-size: 70%;
    }

    ${CheckboxSignup}:checked + & {
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 259 283.75' fillRule='evenodd' clipRule='evenodd' shapeRendering='geometricPrecision' textRendering='geometricPrecision' imageRendering='optimizeQuality'%3E%3Cpath d='M0 0l104 104c5 5 5 14 0 19L0 227h31l89-88c5-5 14-5 19 0l89 88h31L155 123c-5-5-5-14 0-19L259 0h-30l-90 88c-5 5-14 5-19 0L32 0H0z' /%3E%3C/svg%3E");
        }
    }

    a {
        color: #000;
        text-decoration: underline;
    }
`;

export default StyledInput;
