/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Home from '../components/home';
import SingleHome from '../components/singleHome';

export default () => {
  const { initialized, keycloak } = useKeycloak();

  if (!initialized) {
    return null;
  }

  return keycloak?.authenticated
    ? <Home />
    : <SingleHome />;
};
