import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input/input';
import ClipLoader from 'react-spinners/ClipLoader';
import { styles, colors, fonts } from '../styles/globals';
import {
  DEFAULT_TEXT_SIZE,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_HEIGHT,
  DEFAULT_WIDTH,
  DEFAULT_LINE_HEIGHT,
  DEFAULT_FORM_CONTAINER_WIDTH,
  DEFAULT_FORM_CONTAINER_HEIGHT,
} from '../styles/constans';

export const FormField = styled.fieldset`
    position: relative;
    padding: 0px 0px 24px 0px;
`;
export const FormFieldGroup = styled.fieldset`
    position: relative;
    padding: 0px 0px 24px 0px;
    width: 100%;
    display: flex;
    flex: 0 1 50%;
    gap: 20px;
    ${FormField} {
      width: 50%;
    }
    @media (max-width: ${styles.desktopBreakpoint}) {
        display: inline;
        width: 100%;
        ${FormField} {
            width: 100%;
        }
    }
`;

export const CheckboxFormField = styled.fieldset`
    position: relative;
    padding: ${(props) => props.error ? '4px 0px 24px 0px;' : '24px 0px 24px 0px;'};
    @media (max-width: ${styles.desktopBreakpoint}) {
        padding: ${(props) => props.error ? '4px 0px 24px 0px;' : '24px 0px 24px 0px;'};
    }
`;

export const Input = styled.input`
    font-size: ${DEFAULT_TEXT_SIZE};
    margin: 8px 0px 0px 0px;
    width: 100%;
    height: ${DEFAULT_HEIGHT};
    background: ${colors.white};
    border: 1px solid;
    box-sizing: border-box;
    border-radius: ${DEFAULT_BORDER_RADIUS};
    border-color: ${(props) => (props.error ? colors.red : colors.grey)};
    padding: 0px 12px;
    &::placeholder {
        font-family: ${fonts.gotham};
        font-size: ${DEFAULT_TEXT_SIZE};
        color: ${colors.grey2};
    }
    @media (max-width: ${styles.desktopBreakpoint}) {
        width: 100%;
    }
`;

export const Select = styled.select`
    font-family: ${fonts.gotham};
    font-size: ${DEFAULT_TEXT_SIZE};
    margin: 8px 0px 0px 0px;
    width: 100%;
    height: ${DEFAULT_HEIGHT};
    background: ${colors.white};
    border: 1px solid;
    box-sizing: border-box;
    border-radius: ${DEFAULT_BORDER_RADIUS};
    border-color: ${(props) => (props.error ? colors.red : colors.grey)};
    color: ${(props) => (props.empty ? colors.grey2 : colors.slate)};
    padding: 0px 12px;
    @media (max-width: ${styles.desktopBreakpoint}) {
        width: 100%;
    }
`;

export const Option = styled.option`
    color: ${colors.slate};
`;

export const Label = styled.label`
    font-size: ${DEFAULT_TEXT_SIZE};
    line-height: ${DEFAULT_LINE_HEIGHT};
    display: ${(props) => (props.error ? 'none' : 'block')};
    @media (max-width: ${styles.desktopBreakpoint}) {
        font-size: 14px;
        line-height: 18px;
    }
`;

export const FormTitle = styled.h1`
    font-weight: bold;
    font-style: normal;
    font-size: ${DEFAULT_TEXT_SIZE};
    text-transform: uppercase;
    padding: 0px 0px 24px 0px;
`;

export const FormContainer = styled.div`
  padding: 48px 48px 0px;
  width: ${DEFAULT_FORM_CONTAINER_WIDTH};
  height: ${DEFAULT_FORM_CONTAINER_HEIGHT};
  background-color: ${colors.white};
  border-radius: 12px;
  @media (max-width: ${styles.desktopBreakpoint}) {
    width: 100%;
    padding: 36px 70px 0px;
  }
`;

export const ErrorText = styled.p`
    font-size: ${DEFAULT_TEXT_SIZE};
    line-height: ${DEFAULT_LINE_HEIGHT};
    color: ${colors.red};
`;
export const ErrorTextCheckBox = styled.p`
    font-size: ${DEFAULT_TEXT_SIZE};
    line-height: ${DEFAULT_LINE_HEIGHT};
    color: ${colors.red};
    @media (max-width: ${styles.desktopBreakpoint}) {
        font-size: 13px;
    }
`;

export const ErrorTextRequest = styled.p`
    margin-top: 10px;
    text-align: center;
    font-size: ${DEFAULT_TEXT_SIZE};
    line-height: ${DEFAULT_LINE_HEIGHT};
    color: ${colors.red};
    display: ${(props) => (props.error ? '' : 'none')};
    @media (max-width: ${styles.desktopBreakpoint}) {
        font-size: 12px;
    }
`;

export const Button = styled.button`
    font-size: ${DEFAULT_TEXT_SIZE};
    background: ${colors.blue};
    border-radius: 28px;
    width: ${DEFAULT_WIDTH};
    height: 56px;
    font-size: ${DEFAULT_TEXT_SIZE};
    text-align: center;
    text-transform: uppercase;
    color: ${colors.white};
    border: none;
    outline: none;
    border-shadow: none;
    &:hover {
        background: ${colors.lightBlack};
    }
    @media (max-width: ${styles.desktopBreakpoint}) {
        width: 100%;
    }
`;

export const GreetingContainer = styled.div`
    width: ${DEFAULT_FORM_CONTAINER_WIDTH};
    height: ${DEFAULT_FORM_CONTAINER_HEIGHT};
    background-color: ${colors.blue};
    border-radius: 12px;
    text-align: center;
    color: ${colors.white};
    padding: 195px 48px;
    @media (max-width: ${styles.desktopBreakpoint}) {
        width: 100%;
    }
`;
export const GreetingH1 = styled.h1`
    font-family: ${fonts.serif};
    font-weight: 400;
    font-style: normal;
`;

export const TextH1 = styled.p``;

export const StyledPhoneInput = styled(PhoneInput)``;
export const PhoneInputWrapper = styled.div`
    ${StyledPhoneInput} {
        font-size: ${DEFAULT_TEXT_SIZE};
        margin: 8px 0px 0px 0px;
        width: 100%;
        height: ${DEFAULT_HEIGHT};
        background: ${colors.white};
        border: 1px solid;
        box-sizing: border-box;
        border-radius: ${DEFAULT_BORDER_RADIUS};
        border-color: ${(props) => (props.error ? colors.red : colors.grey)};
        padding: 0px 12px;
        &::placeholder {
            font-family: ${fonts.gotham};
            font-size: ${DEFAULT_TEXT_SIZE};
            color: ${colors.grey2};
        }
        @media (max-width: ${styles.desktopBreakpoint}) {
            width: 100%;
        }
    }
`;

export const StyledSpinner = styled(ClipLoader)``;

export const PendingContainer = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: center;
    @media (max-width: ${styles.desktopBreakpoint}) {
        margin-top: 4px;
    }
`;
