import {
  object, string, boolean
} from 'yup';
import 'yup-phone';

export const accessSchema = object().shape({
  fullName: string().required('Full name is required'),
  email: string()
    .email('Invalid email address')
    .required('Email is required'),
  occupation: string().required('Occupation is required'),
  company: string().required('Company is required'),
  affiliation: string().required('Affiliation is required'),
  zipCode: string().required('Zip code is required'),
  country: string().required('Country is required'),
  age: boolean(),
});

export const emailSchema = object().shape({
  email: string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
  name: string()
    .matches(/^[A-Za-zÀ-ÿ]+[A-Za-zÀ-ÿ\s]*$/, 'Please enter a valid name')
    .required('Please enter your name'),
  privacy: boolean().oneOf(
    [true],
    'Please accept the Privacy Policy to continue'
  ),
});

export const surveySchema = object().shape({
  email: string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
  name: string()
    .matches(/^[^\s].+[^\s]*$/g, 'Please enter a valid name')
    .required('Please enter your name'),
  age: string()
    .required(
      'Please select an option'
    ),
  dateDose1: string()
    .required('Please enter a date'),
  noDateDose2: string()
    .required('Please enter a date or select "I don\'t know" option'),
  smartphone: string()
    .required(
      'Please select an option'
    ),
});

export const shippingSchema = object().shape({
  phone: string()
    .phone('US', true, 'Please enter a valid phone number')
    .required('Please enter your phone'),
  address: string()
    .required('Please enter your address'),
  city: string()
    .required('Please enter a city name'),
  state: string()
    .required('Please select a state'),
  zip_code: string()
    .matches(/^[0-9]{5}$/, 'Please enter a valid ZIP code')
    .test('min', 'Please enter a valid ZIP code', val => val ? parseInt(val, 10) > 0 : true)
    .required('Please enter a ZIP code'),
  band_size: string()
    .required('Please select an option'),
});

export const phoneSchema = object().shape({
  phone: string()
    .phone('US', false, 'Please enter a valid phone number')
    .required(),
  name: string()
    .matches(/^[A-Za-zÀ-ÿ]+[A-Za-zÀ-ÿ\s]*$/, 'Please enter a valid name')
    .required('Please enter your name'),
  privacy: boolean().oneOf(
    [true],
    'Please accept the Privacy Policy to continue'
  ),
});

export const phoneGcSchema = object().shape({
  phone: string()
    .phone('US', false, 'Please enter a valid phone number')
    .required(),
  name: string()
    .matches(/^[A-Za-zÀ-ÿ]+[A-Za-zÀ-ÿ\s]*$/, 'Please enter a valid name')
    .required('Please enter your name'),
  privacy: boolean().oneOf(
    [true],
    'Please accept the Privacy Policy to continue'
  ),
});
