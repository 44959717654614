/* eslint-disable react/no-unescaped-entities, no-unused-vars */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import { Helmet } from 'react-helmet';
import Alert from '@material-ui/lab/Alert';
import { colors, device } from '../styles/globals';
import DefaultLayout from '../containers/default';
import '../styles/index.css';
import AllSymbolIcon from '../svgs/all-symbol.svg';
import {
  AccessIcon, DuoIcon, HeartIcon, MindIcon
} from './icons';
import {
  Section,
  SectionDescription,
  SectionItem,
  SectionTitle,
} from './section';
import { MainBanner, MainBannerItem, MainBannerTitle } from './banner';
import {
  FormField, Input, Label, ErrorText
} from './form-styles';
import {
  CheckboxSignup as Checkbox,
  CheckBoxLabelSignup as CheckLabel,
} from './input';
import { accessSchema } from '../schemas';

const Button = styled.button`
  padding: 12px 24px;
  text-transform: uppercase;
  background-color: ${colors.darkGrey};
  color: ${colors.white};
  outline: none;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 24px;
  &:hover {
    cursor: pointer;
    background-color: ${colors.blue};
  }
  &:disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.6;
    background-color: ${colors.grey4};
  }
  @media ${device.tabletM} {
    font-size: 16px;
    width: 100%;
  }
  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

const FormSection = styled.section`
  background-color: ${colors.grey1};
  padding: 120px 30% 200px;
  display: flex;
  flex-direction: column;
  @media ${device.laptopM} {
    padding: 180px 20% 216px;
  }
  @media ${device.tablet} {
    padding: 72px 15%;
  }
  @media ${device.tabletM} {
    padding: 72px 55px;
  }
  @media ${device.mobileL} {
    padding: 72px 35px;
  }
`;

const AllSymbol = styled(AllSymbolIcon)`
  width: 100%;
  height: 99%;
`;
const FormAlert = styled(Alert)`
  && {
    margin-bottom: 16px;
    font-family: Gotham SSm;
    font-size: 12px;
    font-weight: 500;
    color: rgb(80, 86, 97);
  }
`;

export default () => {
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const handleInputChange = (e, callback) => {
    setSubmissionStatus(null);
    callback(e);
  };
  return (
    <DefaultLayout noLogin>
      <Helmet>
        <title>all.health</title>
        <meta
          name="description"
          content="We detect lifestyle diseases early and help members manage existing conditons to influence health outcomes. Sign up to learn more."
        />
      </Helmet>
      <MainBanner>
        <MainBannerItem left isIcon>
          <AllSymbol />
        </MainBannerItem>
        <MainBannerItem right wide>
          <MainBannerTitle>
            we enable the most advanced, continuous healthcare – whenever and
            wherever you need&nbsp;it
          </MainBannerTitle>
        </MainBannerItem>
      </MainBanner>
      <Section>
        <SectionItem left isIcon backgroundColor={colors.cream}>
          <MindIcon />
        </SectionItem>
        <SectionItem right>
          <SectionTitle>we give you peace of mind</SectionTitle>
          <SectionDescription>
            The all.health system is an informed link to your care team that
            makes continuous care possible. Imagine a doctor who knows what is
            going on with your health every day. The all.health platform
            connects you to your care team, and walks with you every step of the
            way, helping you live your best life.
          </SectionDescription>
        </SectionItem>
      </Section>
      <Section reverse>
        <SectionItem left>
          <SectionTitle>
            designed to bring patients and care teams closer
          </SectionTitle>
          <SectionDescription>
            all.health, for the first time, brings together clinical-grade
            medical technology, the expertise of your local care team, and the
            best consumer wearable experience. You get a stronger, continuous
            link with your care team. Our FDA-recognized system collects and
            analyzes 24/7 data on your vitals and biometrics while screening for
            risk of many diseases. With all.health, your care team can see your
            daily health, and act based on your health continuum rather than a
            single snapshot.
          </SectionDescription>
        </SectionItem>
        <SectionItem right isIcon backgroundColor={colors.pink}>
          <HeartIcon />
        </SectionItem>
      </Section>
      <Section>
        <SectionItem left isIcon backgroundColor={colors.blue}>
          <DuoIcon />
        </SectionItem>
        <SectionItem right>
          <SectionTitle>
            created with providers for providers and their patients
          </SectionTitle>
          <SectionDescription>
            Providers are crucial to developing technology that transforms
            healthcare. all.health partners with health systems to integrate
            continuous, remote care into their clinical workflows and
            strengthens clinicians with continuous data and evidence-based
            guidelines. Our AI models provide actionable insights at the right
            moment to deliver personalized, proactive, and predictive
            healthcare. The all.health system helps care teams improve patient
            outcomes and reduces unnecessary office visits, while practicing at
            the top of their license.
          </SectionDescription>
        </SectionItem>
      </Section>
      <Section reverse>
        <SectionItem left>
          <SectionTitle>access to care is care</SectionTitle>
          <SectionDescription>
            Healthcare innovation only works if it works for all people.
            all.health’s system removes the common barriers to healthcare access
            – distance, cost and physical limitations. We work with providers so
            all people have access to the highest quality care wherever they
            want.
          </SectionDescription>
        </SectionItem>
        <SectionItem right isIcon backgroundColor={colors.sage}>
          <AccessIcon />
        </SectionItem>
      </Section>
      {/* <FormSection>
        <SectionTitle>request access</SectionTitle>
        {submissionStatus === 'success' && (
          <FormAlert severity="success">
            Thank you for your request! Our team will review and get back to you
            shortly.
          </FormAlert>
        )}
        {submissionStatus === 'error' && (
          <FormAlert severity="error">
            Failed to submit your request. Please try again later.
          </FormAlert>
        )}
        <Formik
          initialValues={{
            fullName: '',
            email: '',
            occupation: '',
            company: '',
            affiliation: '',
            zipCode: '',
            country: '',
            age: false,
          }}
          validationSchema={accessSchema}
          onSubmit={async (formValues, { setSubmitting, resetForm }) => {
            try {
              const response = await fetch(
                '/.netlify/functions/send-email',
                {
                  method: 'POST',
                  body: JSON.stringify(formValues),
                }
              );
              const responseData = await response.json();
              if (responseData?.error) {
                console.error(responseData?.error);
                setSubmissionStatus('error');
              } else {
                setSubmissionStatus('success');
                resetForm();
              }
            } catch (error) {
              console.error(error);
              setSubmissionStatus('error');
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            touched,
            errors,
            dirty,
          }) => (
            <Form id="requestAccess">
              <FormField>
                <Label htmlFor="fullName">Full name</Label>
                <Input
                  type="text"
                  name="fullName"
                  id="fullName"
                  value={values.fullName}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                  error={touched.fullName && errors.fullName}
                />
                <ErrorText>
                  <ErrorMessage name="fullName" />
                </ErrorText>
              </FormField>

              <FormField>
                <Label htmlFor="email">Email address</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                  error={touched.email && errors.email}
                />
                <ErrorText>
                  <ErrorMessage name="email" />
                </ErrorText>
              </FormField>

              <FormField>
                <Label htmlFor="occupation">Occupation</Label>
                <Input
                  type="text"
                  name="occupation"
                  id="occupation"
                  value={values.occupation}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                  error={touched.occupation && errors.occupation}
                />
                <ErrorText>
                  <ErrorMessage name="occupation" />
                </ErrorText>
              </FormField>

              <FormField>
                <Label htmlFor="company">Company</Label>
                <Input
                  type="text"
                  name="company"
                  id="company"
                  value={values.company}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                  error={touched.company && errors.company}
                />
                <ErrorText>
                  <ErrorMessage name="company" />
                </ErrorText>
              </FormField>

              <FormField>
                <Label htmlFor="affiliation">Affiliation</Label>
                <Input
                  type="text"
                  name="affiliation"
                  id="affiliation"
                  value={values.affiliation}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                  error={touched.affiliation && errors.affiliation}
                />
                <ErrorText>
                  <ErrorMessage name="affiliation" />
                </ErrorText>
              </FormField>

              <FormField style={{ width: '40%' }}>
                <Label htmlFor="zipCode">ZIP Code</Label>
                <Input
                  small
                  type="text"
                  name="zipCode"
                  id="zipCode"
                  value={values.zipCode}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                  error={touched.zipCode && errors.zipCode}
                />
                <ErrorText>
                  <ErrorMessage name="zipCode" />
                </ErrorText>
              </FormField>

              <FormField>
                <Label htmlFor="country">Country</Label>
                <Input
                  type="text"
                  name="country"
                  id="country"
                  value={values.country}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                  error={touched.country && errors.country}
                />
                <ErrorText>
                  <ErrorMessage name="country" />
                </ErrorText>
              </FormField>

              <FormField row>
                <Checkbox
                  name="age"
                  id="age"
                  value={values.age}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                />
                <CheckLabel htmlFor="age">Over 18 years old</CheckLabel>
              </FormField>
              <Button
                type="submit"
                disabled={
                  !dirty || isSubmitting || Object.keys(errors).length > 0
                }
              >
                Send request
              </Button>
            </Form>
          )}
        </Formik>
      </FormSection> */}
    </DefaultLayout>
  );
};
